<template>
  <div
    class="monitor-body"
    ref="monitorBody"
  >
    <iframe
      :src="monitorUrl"
      @load="loadPage"
      frameborder="0"
      width="100%"
      height="100%"
      sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox"
      @click="onClick"
    />
    <div
      class="monitor-body-event"
      v-if="$listeners.click"
      @click="$listeners.click"
    ></div>
  </div>
</template>

<script>
import { Loading } from "element-ui"
export default {
  data() {
    return {
      loading: null,
    }
  },
  props: {
    channelNo: {
      type: Number | String,
    },
    cameraIndexCode: {
      type: Number | String,
    },
    quality: {
      type: Number | String,
      default: 1,
    },
    templateId: {
      type: String,
      default: "simple",
    },
  },
  created() {
    this.$nextTick(() => {
      this.loading = Loading.service({
        lock: true,
        text: "资源加载中",
        target: this.$refs.monitorBody,
        fullscreen: false,
        background: "transparent",
      })
    })
  },
  computed: {
    monitorUrl() {
      return `${process.env.VUE_APP_BASE}/monitor?channelNo=${this.channelNo}&cameraIndexCode=${this.cameraIndexCode}&templateId=${this.templateId}&quality=${this.quality}`
    },
  },
  methods: {
    loadPage() {
      this.loading.close()
    },
    onClick() {
      console.log("点击了画面")
    },
  },
}
</script>

<style lang="less" scoped>
iframe {
  width: 100%;
  height: 100%;
}
.monitor-body {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  position: relative;
  &-event {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
</style>
